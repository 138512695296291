const routes = {
	NOTIFICATIONS: '/tabs/notifications',
	VIDEO_MESSAGE: '/tabs/videomessage',
	MESSAGE_CONFIRM: '/tabs/messageconfirm',
	NEW_THINGS: '/tabs/new',
	PROMO: '/tabs/promo',
	SMS: '/tabs/sms',
	SHOPSCOCKPIT: '/tabs/shopscockpit',
	FARMERLIST: '/tabs/farmerlist',
	LOGIN: '/login',
	TABS: '/tabs',
};

export default routes;
