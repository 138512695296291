/* eslint-disable no-inner-declarations */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { config } from './assets/config';

// Import your translation files
import alTranslation from './locales/al/translation-al.json';
import enTranslation from './locales/en/translation-en.json';
import tgSidCTranslation from './locales/tg-sidc/translation-tg-sidc.json';

import enNATCCOTranslation from './locales/en-natcco/translation-en_natcco.json';

import { dev } from './lib/Helpers/utils';

export const resources = {
	en: {
		translation: enTranslation,
	},
	al: {
		translation: alTranslation,
	},
	tg_sidc: {
		translation: tgSidCTranslation,
	},
	en_natcco: {
		translation: enNATCCOTranslation,
	},
};

i18n.use(initReactI18next).init({
	resources,
	lng: config.locale,
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;

/*

This part does the check on missing translations

How to create translations:
- see the differential in the console log in dev mode, enabling the right language - see init of i18n below
- put it in ChatGPT with the prompt :"Convert this JSON object from English to Albanian. Only translate the value, not the keys."
- place the translation in the place in the translation file

all this code may need to go to the admin panel as this is not really the best place to put this

*/
if (dev) {
	const missingTranslations = {};

	function addMissing(page, label, translation) {

		if (translation.length == 0) return;
		if (!missingTranslations[page]) missingTranslations[page] = {};
		if (!missingTranslations[page][label]) missingTranslations[page][label] = {};
		missingTranslations[page][label] = translation;
	}

	const transToCheck = resources[config.locale]['translation'];
	Object.keys(resources['en']['translation']).forEach(page => {
		const labels = Object.keys(resources['en']['translation'][page]);
		labels.forEach(label => {
			if (!transToCheck[page]) {
				addMissing(page, label, resources['en']['translation'][page][label]);
			} else if (!transToCheck[page][label]) addMissing(page, label, resources['en']['translation'][page][label]);
		});
	});

	if (Object.keys(missingTranslations).length > 0) console.warn('Missing translations', JSON.stringify(missingTranslations, null, 2));
}
