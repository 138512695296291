import { collectionGroup, doc, getDoc, getDocs, setDoc, writeBatch } from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { config } from '../../assets/config';
import { Farmer } from '../Farmers/farmer.schema';
import { loadFarmersFromShop } from '../Farmers/farmer.store';
import { firestore } from '../Helpers/firebase';
import { StatsKeys } from '../Stats/stats-keys';
import { User } from '../User/user.schema';
import { Shop, shopSchema } from './shop.schema';

export const shopStore$ = new BehaviorSubject<Shop[]>([]);

// if we are in multi-tenant and there is a multi-tenant defined, we give that path
export function getShopDocPath(user: User) {
	if (user == undefined) {
		console.error('GetDoc with undefined user received for shops');
		return 'error';
	}

	// if there is a user-defined tenant, we use that one
	if (user.active_tenant_id && user.active_tenant_id.length > 0) return `tenants/${user.active_tenant_id}/shops`;

	// otherwise we resort to configured one we could know
	if (config.tenants.length == 1) return `tenants/${config.tenants[0].id}/shops`;

	return 'stores';
}

export function setupShopStore() {}

export function logOutShopStore() {
	shopStore$.next([]);
}

export async function updateAddShop(shopRawData: Shop, user: User | undefined): Promise<boolean> {
	if (!user) {
		console.error('Cannot save Store - user undefined');
		return false;
	}

	const shop: Shop = { ...shopRawData, updatedAt: Date.now() };

	// we have a batch because we earlier wrote two docs
	const batch = writeBatch(firestore);
	const docRef1 = doc(firestore, getShopDocPath(user), shop._id);
	batch.set(docRef1, shop);

	return batch.commit().then(async () => {
		const newList = shopStore$.getValue().filter(shopInList => shop._id !== shopInList._id);
		shopStore$.next([...newList, shop]);
		return true;
	});
}

// this function is to support all sorts of db migrations
export function shopMigrationCheck(rawData: any): Shop {
	const empty = shopSchema.parse({});
	return { ...empty, ...rawData } as Shop;
}

export async function loadShopDataByUser(user: User) {
	const shopID: string = user.stores[0];
	const docRef = doc(firestore, getShopDocPath(user), shopID);
	const docSnap = await getDoc(docRef);

	if (!docSnap.exists()) {
		try {
			// lets get the data from to no-multitenant location
			const docRefNoMultiTenant = doc(firestore, 'stores', shopID);
			const docSnapNoMultiTenant = await getDoc(docRefNoMultiTenant);

			// if we have data to migrate - maybe migrate - if the user has tenant ID assigned
			if (docSnapNoMultiTenant.exists()) {
				const rawData = docSnapNoMultiTenant.data();

				// and set store data to the multi-tenant location
				const shopToMigrate = shopMigrationCheck(rawData);
				//  if (config.default_tenant != undefined) tenant_id = config.default_tenant.id;

				if (user.active_tenant_id && user.active_tenant_id.length > 0) {
					console.warn('Migrating shop', shopToMigrate);
					setDoc(docRef, shopToMigrate);
				} else console.warn('NOT migrating shop - user has no tenant assigned');

				// we not yet delete the old reference
				// todo here

				return finaliseLoad(shopToMigrate, user);
			} else console.error('Shop multitenant-issue - root shop data does not exist?');
		} catch (e) {
			console.error('Shop Migration failure', e);
		}
	}

	// if there was data, we load it
	if (docSnap.exists()) {
		const loadedShop: Shop = shopMigrationCheck(docSnap.data());
		return finaliseLoad(loadedShop, user);
	}

	function finaliseLoad(shop: Shop, user: User) {
		shopStore$.next([shop]);
		return loadFarmersFromShop(shop, user);
	}
}

export function updateFarmersForShop(farmers: Farmer[] | undefined, shop: Shop | undefined, user: User | undefined) {
	if (farmers === undefined || shop === undefined || user === undefined) return;

	let isMissing = false;

	const farmerIDs = farmers.map(farmer => farmer._id);
	for (const farmerID of farmerIDs) {
		if (!shop.list_of_farmers.includes(farmerID)) {
			isMissing = true;
			break;
		}
	}
	if (isMissing) {
		updateAddShop({ ...shop, list_of_farmers: farmerIDs }, user);
	}
}

/*
  Shop stats:
  - count of shops (now one)
*/
export function convertShopListIntoStats(shoplist: Shop[]) {
	const resultOfCount: { [key: string]: number | object } = {};

	const activeShops = shoplist; // .filter((shop) => farmer.status == FarmerStatus.Active);
	resultOfCount[StatsKeys.SHOP_count] = activeShops.length;

	return resultOfCount;
}

export async function provideAllShopStats() {
	const list = await provideAllShops();
	return convertShopListIntoStats(list);
}

export async function provideAllShops(): Promise<Shop[]> {
	const collectionGroupRef = collectionGroup(firestore, 'shops');
	return getDocs(collectionGroupRef).then(async collectionSnap => {
		let list: any = [];
		collectionSnap.forEach(doc => {
			list = [...list, doc.data()];
		});

		return list;
	});
}
