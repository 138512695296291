import type { AppConfig } from './config-client.schema';

export const config: AppConfig = {
	appDomain: 'https://ekoopkonek.me',
	countryCode: '+63',
	locale: 'en_natcco',
	localeTranslations: import('../locales/en-natcco/translation-en_natcco.json'),

	pwaItems: {
		name: 'eKoop Konek',
		short_name: 'eKoop Konek',
		description: '',
		theme_color: '#004aa4',
	},

	allowedNumbers: [],

	maxSMSLength: 160,

	firebaseConfig: {
		apiKey: 'AIzaSyCsjA5JVyBkSLqadFvQdeVez8iC7WsN1-A',
		authDomain: 'natcco-db.firebaseapp.com',
		projectId: 'natcco-db',
		storageBucket: 'natcco-db.appspot.com',
		messagingSenderId: '455722057052',
		appId: '1:455722057052:web:31b2237a9d73adff505599',
		measurementId: 'G-7R0XP08340',
		vapidKey: 'BF0jwIGKRHK_B2bJxjNs-QO7Y-NU12shKacutKs44NnVndhY4MqF7eaQopBmmX0IMdeYAAW95UPZKHvpvzCHIcE',
		recaptchaV3publicKey: '6LdPB1YoAAAAAJibOyadgbp2DBmuHaIOqI3uDN-_',
	},

	apiDev: 'http://127.0.0.1:5001/natcco-db/us-central1/',
	apiProd: 'https://us-central1-natcco-db.cloudfunctions.net/',

	appConfig: {
		hasPrivacyNotice: false,
		hasTandC: false,
		experimental: false,
		isNATCCO: true,
		shopActionsConfig: {
			userTooLongInactive: 7 * 24 * 60 * 60 * 1000, // one week
		},
		wantsMiddleName: true,
	},

	tenants: [
		{
			id: '1',
			short_title: 'CamSur',
		},
		{
			id: '2',
			short_title: 'Mambajao',
		},
		{
			id: '3',
			short_title: 'Barbaza',
		},
		{
			id: '4',
			short_title: 'NATCCO',
		},
		{
			id: '5',
			short_title: 'MHRMC',
		},
	],
};
