import { z } from 'zod';

export const permissionsSchema = z.object({
	can_use_app: z.boolean().default(true),
	can_manage_farmers: z.boolean().default(true),
	can_send_campaigns: z.boolean().default(true),
	can_use_promo_tab: z.boolean().default(true),
	can_use_free_text: z.boolean().default(false),
	can_send_1_sms_to_farmer_text: z.boolean().default(false),

	can_use_admin: z.boolean().default(false),
	can_create_invite_codes: z.boolean().default(false),
	can_edit_crops: z.boolean().default(false),
	can_top_up_users: z.boolean().default(false),
	can_set_permissions: z.boolean().default(false),
	can_admin_shops: z.boolean().default(false),

	can_create_shortcodes: z.boolean().default(false),
	can_admin_notifications: z.boolean().default(false),

	can_admin_tenants: z.boolean().default(false),
	can_set_super_permissions: z.boolean().default(false),
});

export type Permissions = z.infer<typeof permissionsSchema>; //  & { [key: string]: boolean };

export const adminLabels: { [key: string]: string } = {
	can_manage_farmers: 'User can add/change farmers',
	can_send_campaigns: 'User can send campaigns',
	can_use_promo_tab: 'User can use promo tab',
	can_use_app: 'User can use the app at all',
	can_use_free_text: 'Can use free text option in campaign tree',
	can_send_1_sms_to_farmer_text: 'Can send an individual message to a farmer',

	can_use_admin: 'TENANT ADMIN - enable user for admin menu item',
	can_admin_shops: 'TENANT ADMIN - Access to Shop Cockpit',
	can_set_permissions: 'TENANT ADMIN - can set permissions users within Tenant',
	can_edit_crops: 'TENANT ADMIN - can edit crops',
	can_top_up_users: 'TENANT ADMIN - can topup users',

	can_create_invite_codes: 'TENANT ADMIN - EXPERIMENTAL - User can send invitation codes',
	can_create_shortcodes: 'TENANT ADMIN - EXPERIMENTAL - Manage shortcodes',
	can_admin_notifications: 'TENANT ADMIN - EXPERIMENTAL - Receive push notifications',

	can_admin_tenants: 'SUPER ADMIN - can admin tenant for all users',
	can_set_super_permissions: 'SUPER ADMIN- can set permissions for all users',
};

export const tenant_admin_rights = ['can_use_admin', 'can_set_permissions'];
export const super_rights = ['can_admin_tenants', 'can_set_super_permissions'];

export const adminChipColors: { [key: string]: string } = {
	can_manage_farmers: 'success',
	can_send_campaigns: 'success',
	can_use_promo_tab: 'success',
	can_use_app: 'success',
	can_use_free_text: 'success',
	can_send_1_sms_to_farmer_text: 'success',

	can_use_admin: 'warning',
	can_create_invite_codes: 'warning',
	can_create_shortcodes: 'warning',
	can_admin_shops: 'warning',
	can_admin_notifications: 'warning',
	can_set_permissions: 'warning',
	can_edit_crops: 'warning',
	can_top_up_users: 'warning',

	can_admin_tenants: 'danger',
	can_set_super_permissions: 'danger',
};
