import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import * as Sentry from '@sentry/react';
import { registerSW } from 'virtual:pwa-register';
import { auth } from './lib/Helpers/firebase';

import { BeforeInstallPromptEvent, beforeInstalledEventStore$ } from './lib/Install/install-helper';
import { updateFunction } from './lib/Install/update-helper';

import { t } from 'i18next';
import { config } from './assets/config';
import './index.css';
import { dev, presentToast } from './lib/Helpers/utils';
import { setTranslations } from './smalli18n';

// setup Sentry
if (!dev)
	Sentry.init({
		dsn: 'https://a863bd166dfff6cbe2b16286fe863760@o4506003097387008.ingest.sentry.io/4506003112067072',
		integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
		// Performance Monitoring
		tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});

// setup the service worker

const updateSW = registerSW({
	onNeedRefresh() {
		setTimeout(() => {
			// we don't kick the user already logged in
			if (auth.currentUser === null) {
				presentToast('middle', t('general.pleasewait'));
				updateSW(true);
			}
			if (auth.currentUser !== null) updateFunction.next(updateSW);
		}, 200);
	},
});

// Listen for the beforeinstallprompt event to support installation via app
window.addEventListener('beforeinstallprompt', (event: BeforeInstallPromptEvent) => {
	event.preventDefault();
	beforeInstalledEventStore$.next(event);
});

// loading the local translations for our replacement library
if (dev)
	config.localeTranslations.then(m => {
		setTranslations(m.default);
		console.log('Loaded translations', m.default);
	});

// if the current route starts with /v/ we will redirect to the remote API for the shortcodes
// this should never kick in as we have configured the service worker not to cache /v/ routes
// nevertheless, we keep it as safeguard until we are sure
let bootApp = true;
if (window.location.pathname.startsWith('/v/')) {
	bootApp = false;
	console.log('Redirecting to', dev ? config.apiDev : config.apiProd);

	window.location.href = dev
		? config.apiDev + 'v/' + window.location.pathname.split('/v/')[1]
		: config.apiProd + 'v/' + window.location.pathname.split('/v/')[1];
}

// let's check if we can boot the app
if (bootApp)
	ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
		<React.StrictMode>
			<App></App>
		</React.StrictMode>
	);
