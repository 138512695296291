import { z } from 'zod';

export enum FarmerStatus {
	New = 'new',
	Active = 'active',
	Disabled = 'disabled',
}

export const farmerSchema = z.object({
	_id: z.string().default(() => 'u' + Math.random() + '-' + Date.now()),
	farm_size: z.union([z.number().default(0), z.string().default('')]),
	first_name: z.string().default(''),
	last_name: z.string().default(''),

	middle_name: z.optional(z.string().default('')),

	list_of_crops: z.string().array().default([]),
	mobile_number: z.string().default(''),

	member_number: z.optional(z.string().default('')),
	comments: z.string().default(''),

	shop_id: z.string().default(''),
	created_by_id: z.string().default(''),

	barangay: z.string().default(''),
	barangayID: z.string().default(''),

	importUnknownJSON: z.optional(z.string().default('')),

	totalRatingForShop: z.string().default('3'),
	totalRatingForTenant: z.string().default('3'),

	createdAt: z.number().default(() => Date.now()),
	updatedAt: z.number().default(() => Date.now()),
	status: z.nativeEnum(FarmerStatus).default(FarmerStatus.New),
});

export type Farmer = z.infer<typeof farmerSchema>;
