import { getPlatforms } from '@ionic/core';
import { User as FirebaseUser, Unsubscribe, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore';
import localforage from 'localforage';
import { BehaviorSubject } from 'rxjs';
import { config } from '../../assets/config';
import { awardedBadgesStore$, initBadgeListener, runBadgeCheck } from '../Badges/badges.store';
import { loadCampaignForUser } from '../Campaign/campaign.store';
import { loadCropsAndSubscribe } from '../Crop/crop.store';
import { auth, firestore, getFCMToken, getNotificationPermission } from '../Helpers/firebase';
import { LocalSaveStates } from '../Helpers/localstorage';
import { capitalizeFirstLetter, dev } from '../Helpers/utils';
import { loadContactUsForUserAndSubscribe } from '../Promo/ContactUs/contactus.store';
import { loadExploreMoreAndSubscribe } from '../Promo/ExploreMore/exploremore.store';
import { loadFAQAndSubscribe } from '../Promo/FAQ/faq.store';
import { loadPromotionalMessageAndSubscribe } from '../Promo/PromotionalMessage/promotionalmessage.store';
import { Shop } from '../Shop/shop.schema';
import { loadShopDataByUser } from '../Shop/shop.store';
import { StatsKeys } from '../Stats/stats-keys';
import { loadStatsForUserAndSubscribe, saveStatsToRemote, setStatsKeyCount } from '../Stats/stats.store';
import { loadQuestions } from '../Templates/question.store';
import { loadTemplates } from '../Templates/templates.store';
import { permissionsSchema } from './permission.schema';
import { loadPermissionsForUser } from './permission.store';
import { User, userSchema } from './user.schema';
import { walletSchema } from './wallet.schema';

export const userStore$: BehaviorSubject<User | undefined> = new BehaviorSubject<User | undefined>(undefined);

function getDocPath() {
	return 'users';
}

let authUnsubscribe: Unsubscribe | undefined = undefined;

export function logOutUserStore() {
	if (authUnsubscribe) {
		authUnsubscribe();
		authUnsubscribe = undefined;
	}
	userStore$.next(undefined);
}

export const updateAddUser = async (userRawData: User): Promise<boolean> => {
	const user = { ...userRawData, updated: Date.now() };
	const docRef = doc(firestore, getDocPath(), user.uid);

	return setDoc(docRef, user).then(() => {
		userStore$.next(user);

		return true;
	});
};

export const updateUserProps = async (props: { [key: string]: any }): Promise<boolean> => {
	const newUserData = { ...userStore$.getValue(), updated: Date.now() };

	Object.keys(props).forEach((key: string) => {
		//@ts-ignore
		if (typeof newUserData[key] !== 'undefined') newUserData[key] = props[key];
		else console.error('updateUserProps - cannot update prop ', key);
	});
	//@ts-ignore
	return updateAddUser(newUserData);
};

export const addShopToUser = async (storeToAdd: Shop): Promise<boolean | undefined> => {
	const userData = userStore$.getValue();
	if (userData === undefined) return;

	if (!userData.stores.includes(storeToAdd._id)) {
		const newUserdata = {
			...userData,
			stores: [...userData.stores, storeToAdd._id],
		};
		return updateAddUser(newUserdata);
	} else return true;
};

export const addPNTokenToCurrentUser = async (token: string) => {
	if (token === '' || token === 'notoken') return;

	const newUserData = userStore$.getValue();
	if (newUserData === undefined) return;
	if (!newUserData.pnTokens.includes(token)) {
		newUserData.pnTokens = [token, ...newUserData.pnTokens];
		return updateAddUser(newUserData);
	}
};

const loadStoreDataForUser = (user: User) => {
	const storeIDtoLoad = user.stores[0];
	if (storeIDtoLoad != undefined || user.stores.length !== 0) {
		return loadShopDataByUser(user);
	} else return false;
};

// this function is to support all sorts of db migrations
export function userMigrationCheck(rawData: any): User {
	// migration of the embedded schema
	if (rawData.wallet) rawData.wallet = walletSchema.parse(rawData.wallet);
	if (rawData.permissions) rawData.permissions = permissionsSchema.parse(rawData.permissions);

	// defaulting any missing properties
	if (!rawData.version_installed) rawData['version_installed'] = APP_VERSION;
	if (!rawData.permissions) rawData['permissions'] = permissionsSchema.parse({});
	if (!rawData.wallet) rawData['wallet'] = walletSchema.parse({});
	if (!rawData.awarded_badges) rawData.awarded_badges = [];
	if (!rawData.login_count) rawData['login_count'] = 0;

	if (rawData['active_tenant_id'] && rawData['active_tenant_id'] == '' && config.tenants && config.tenants.length === 1) {
		rawData['active_tenant_id'] = config.tenants[0].id;
		rawData['tenants'] = [config.tenants[0].id];
	}

	if (!rawData['active_tenant_id'] && config.tenants && config.tenants.length === 1) {
		rawData['active_tenant_id'] = config.tenants[0].id;
		rawData['tenants'] = [config.tenants[0].id];
	}

	// other migrations
	if (rawData.first_name && rawData.last_name) {
		rawData.first_name = capitalizeFirstLetter(rawData.first_name);
		rawData.last_name = capitalizeFirstLetter(rawData.last_name);
	}

	// clean possible invalid tokens
	if (Array.isArray(rawData['pnTokens'])) rawData['pnTokens'] = rawData['pnTokens'].filter(token => token.length > 10);

	// make sure we have defaults for new things created
	const empty = userSchema.parse({
		wallet: walletSchema.parse({}),
		permissions: permissionsSchema.parse({}),
	});

	return { ...empty, ...rawData } as User;
}

export function updateUserWalletWithSMSsent(count: number) {
	const curUser = { ...(userStore$.getValue() as User) };

	userStore$.next({
		...curUser,
		wallet: walletSchema.parse({
			...curUser.wallet,
			sms_balance: curUser.wallet.sms_balance - count,
		}),
	});
}

//
// Multi-tenant choice - users will stay at root level as it holds the core info on the user config
//
export async function loadUserDataFromFirestore(uid: string, isOTP?: boolean, pinCode?: string): Promise<User | false> {
	const docRef = doc(firestore, getDocPath(), uid);
	const docSnap = await getDoc(docRef);

	// existing user found
	if (docSnap.exists()) {
		const rawData = docSnap.data();

		// migrate/validate any userdata
		let existingUser: User = { ...userMigrationCheck(rawData) };

		// update userdata - fix sms balance - code should be removed in future
		let walletSMSBalance = existingUser.wallet.sms_balance;
		try {
			if (walletSMSBalance === 0) {
				const balanceRef = doc(firestore, `/users/${uid}/transactions`, 'balance');
				const balanceSnap = await getDoc(balanceRef);

				if (balanceSnap.exists()) {
					const data = balanceSnap.data() as { balance: number };

					if (data.balance && data.balance > 0) {
						walletSMSBalance = data.balance;
						existingUser.wallet.sms_balance = data.balance;
					}
				}
			}
		} catch (error) {
			console.error('Error while fetching balance:', error);
		}

		// update some userstats
		const last_login = Date.now();
		existingUser.login_count = existingUser.login_count + 1;
		existingUser.version_installed = Number(APP_VERSION);
		existingUser.updated = Date.now();
		if (existingUser.has_pin) await localforage.setItem(LocalSaveStates.HAS_PIN_SET, true);

		existingUser.wallet = { ...existingUser.wallet, sms_balance: walletSMSBalance };

		const pnTokens = existingUser.pnTokens;
		if (!dev && getNotificationPermission() === 'granted') {
			try {
				const currentPNToken = await getFCMToken();
				if (!pnTokens.includes(currentPNToken)) pnTokens.push(currentPNToken);
			} catch (e) {
				console.log('Error getting PN token', e);
			}
		}

		// update last_100_logins to reflect the last 100 logins which are stored in last_login
		const last_100_logins = existingUser.last_100_logins === undefined ? [] : [...existingUser.last_100_logins];
		last_100_logins.push(last_login);
		if (last_100_logins.length > 100) last_100_logins.shift();

		// update server record
		existingUser = {
			...existingUser,
			otp_usage_count: existingUser.otp_usage_count + (isOTP === undefined ? 0 : 1),
			login_count: existingUser.login_count + 1,
			platforms: getPlatforms(),
			userAgent: window.navigator.userAgent,
			version_installed: Number(APP_VERSION),
			updated: last_login,
			last_100_logins,
			last_login,
			pnTokens,
			wallet: { ...existingUser.wallet, sms_balance: walletSMSBalance },
		};

		if (pinCode !== undefined) {
			existingUser.pin = pinCode;
		}

		setDoc(docRef, existingUser, { merge: true });
		userStore$.next(existingUser);

		// initiate the loading of all other remaining data
		await loadStatsForUserAndSubscribe(existingUser);
		await loadStoreDataForUser(existingUser);
		await loadPermissionsForUser(existingUser);
		await loadCampaignForUser(existingUser);

		loadContactUsForUserAndSubscribe(existingUser);

		let tenant_id = existingUser.active_tenant_id;
		if (config.tenants && config.tenants.length == 1 && tenant_id == '') tenant_id = config.tenants[0].id;

		loadCropsAndSubscribe(tenant_id);
		loadQuestions(tenant_id);
		loadFAQAndSubscribe(tenant_id);
		loadExploreMoreAndSubscribe(tenant_id);
		loadPromotionalMessageAndSubscribe(tenant_id);
		awardedBadgesStore$.next(existingUser.awarded_badges);
		loadFAQAndSubscribe(tenant_id);
		loadTemplates(tenant_id);

		setStatsKeyCount(StatsKeys.LOGIN_count, existingUser.login_count + 1);

		saveStatsToRemote();

		if (config.appConfig.experimental || dev) {
			initBadgeListener();
			runBadgeCheck();
		}

		startListeningForUserChanges(existingUser);
		return existingUser;
	} else {
		// we only support no user records for the gensis user
		if (auth.currentUser?.phoneNumber !== '+31612345678') return false;

		// no user found - new one
		let newUser = userSchema.parse({
			uid,
			login_count: 1,
			is_new: true,
			phoneNumber: auth.currentUser?.phoneNumber,
			wallet: walletSchema.parse({}),
			permissions: permissionsSchema.parse({
				can_use_app: true,
				can_manage_farmers: true,
				can_send_campaigns: true,
				can_use_promo_tab: true,
			}),
		});

		const tenant_id = config.tenants.length > 0 ? config.tenants[0].id : '';
		newUser = {
			...newUser,
			active_tenant_id: tenant_id,
			tenants: config.tenants.length > 0 ? config.tenants.map(tenant => tenant.id) : [],
		};

		userStore$.next(newUser);
		startListeningForUserChanges(newUser);
		return newUser;
	}
}

export async function startListeningForUserChanges(user: User) {
	const { uid } = user;
	let unsubscribe: (() => void) | null = null;

	const handleAuthStateChanged = (user: FirebaseUser | null) => {
		if (user) {
			if (!unsubscribe) {
				const docRef = doc(firestore, 'users', uid);

				unsubscribe = onSnapshot(
					docRef,
					docSnap => {
						if (docSnap.exists()) {
							const existingUser = { ...userMigrationCheck(docSnap.data()) };
							userStore$.next(existingUser);
						}
					},
					error => {
						console.log('Error loading document in User listener', error);
					}
				);
			}
		} else {
			if (unsubscribe) {
				unsubscribe();
				unsubscribe = null;
			}
		}
	};

	authUnsubscribe = onAuthStateChanged(auth, handleAuthStateChanged);
}
